import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import './ContactForm.css';
import ReCAPTCHA from 'react-google-recaptcha';

function ContactForm() {
  const [captchaVal, setCaptchaVal] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_ppo90o4', 'template_qf56mfw', form.current, {
        publicKey: '9RFn30y7X_y-OHUCB',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setSubmitted(true); // Set state to indicate form submission
          form.current.reset(); // Reset form fields
          setCaptchaVal(null); // Reset reCAPTCHA
        },
        (error) => {
          console.log('FAILED...', error.text);
          // Handle error scenario if needed
        }
      );
  };

  return (
    <div className="contact-form">
      <h1 className="contact-heading">
        <span className="change_color">Contact</span> us
      </h1>
      <h2>Let's talk!</h2>
      <p>Go ahead and send us a brief of your requirements!</p>
      <form ref={form} onSubmit={sendEmail}>
        <div className="input-fields">
          <label htmlFor="user_name">Full name</label>
          <input type="text" name="user_name" placeholder="Type your name" required />
        </div>
        <div className="input-fields">
          <label htmlFor="user_email">Email</label>
          <input type="email" name="user_email" placeholder="Type your email" required />
        </div>
        <div className="input-fields">
          <label htmlFor="user_number">Phone number</label>
          <input type="number" name="user_number" placeholder="+44 00000-00000" required />
        </div>
        <div className="input-fields">
          <label htmlFor="message">Message</label>
          <textarea name="message" />
        </div>
        <ReCAPTCHA
          className="reCaptcha-style mt-3"
          sitekey="6LduRfUpAAAAABpNzAvA-m3LI814pz11ZD48yIws"
          onChange={(val) => setCaptchaVal(val)}
        />
        
        <button type="submit" value="Send" className="submit-btn" disabled={!captchaVal} > 
          Send
        </button>
        {submitted && <p className="submit-message">Message submitted successfully!</p>}
      </form>
    </div>
  );
}

export default ContactForm;
